import { jsx as _jsx } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { OEMSpeedBump } from '@autooem/shared-components-library/OEMSpeedBump';
import { useTranslation } from '@octagon/i18n';
import { AEM_CONTENT_TRANSLATION_PARAM, AEM_MODELS, BRAND_LAND_ROVER, PROD_LOGOUT_URL, QA_LOGOUT_URL, RETURN_OBJ, } from '../../constants/constants';
import { getLoggedInStatus } from '../../utils/UserLoggedInDetails';
import ExtendSession from '../ExtendSession/ExtendSession';
import { getEnvironment } from '../../utils/Utils';
const InActiveSpeedBump = (props) => {
    const { timeout, warningTimeout } = props;
    const { t } = useTranslation(AEM_CONTENT_TRANSLATION_PARAM.COMMON_TAG);
    const { DIALOG } = AEM_MODELS;
    const { inActiveSpeedBumpDialog } = t(DIALOG, RETURN_OBJ);
    const [isPrompted, setPrompted] = useState(false);
    const setIdle = useRef(false);
    const isLoggedIn = getLoggedInStatus();
    const [isSessionExtendRequired, setIsSessionExtendRequired] = useState(false);
    const { reset } = useIdleTimer({
        timeout: timeout,
        promptBeforeIdle: warningTimeout,
        events: [
            'keydown',
            'DOMMouseScroll',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
            'MSPointerMove',
            'visibilitychange',
            'focus',
        ],
        onIdle: () => {
            if (isLoggedIn) {
                setPrompted(false);
                setIdle.current = true;
                window.location.replace(getEnvironment().isProd ? PROD_LOGOUT_URL : QA_LOGOUT_URL);
            }
        },
        onAction: (event) => {
            if (event.type === 'mousedown') {
                setIsSessionExtendRequired(!isSessionExtendRequired);
            }
        },
        onPrompt: () => {
            setPrompted(true);
        },
    });
    const inActiveSecondaryModalAction = () => {
        setIsSessionExtendRequired(true);
        reset();
        setPrompted(false);
    };
    const inActivePrimaryModalAction = () => {
        setPrompted(false);
        window.location.replace(getEnvironment().isProd ? PROD_LOGOUT_URL : QA_LOGOUT_URL);
    };
    const content = {
        title: inActiveSpeedBumpDialog?.title?.text,
        description: inActiveSpeedBumpDialog?.subTitle?.text,
        primaryActionLabel: inActiveSpeedBumpDialog?.links?.inActivePrimaryActionButton?.value,
        secondaryActionLabel: inActiveSpeedBumpDialog?.links?.inActiveSecondaryActionButton?.value,
        href: '',
        isAutoRedirect: false,
        hideModal: () => '',
        primaryActionIconNotRequired: true,
        secondaryActionIconNotRequired: true,
        inActivePrimaryModalAction: inActivePrimaryModalAction, //signout
        inActiveSecondaryModalAction: inActiveSecondaryModalAction,
    };
    if (isLoggedIn && isPrompted) {
        return (_jsx("div", { "data-testid": "prompt", children: _jsx(OEMSpeedBump, { brand: BRAND_LAND_ROVER, content: content }) }));
    }
    else if (isSessionExtendRequired) {
        return _jsx(ExtendSession, {});
    }
};
export default InActiveSpeedBump;
