import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { API_URLS } from '../constants/constants';
import { getEnvironment } from './Utils';
export const LoggedInDetailsContext = createContext(undefined);
// @ts-ignore
const UserLoggedInDetails = ({ children }) => {
    const [status, setStatus] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!getEnvironment().isLocal) {
                    const response = await axios.get(API_URLS.Header);
                    const value = await response.data;
                    setStatus(value);
                }
            }
            catch (e) {
                setStatus(false);
            }
        };
        fetchData();
    }, []);
    return (_jsx(LoggedInDetailsContext.Provider, { value: { status }, children: children }));
};
export default UserLoggedInDetails;
/* eslint-disable */
export const getLoggedInStatus = () => {
    const { status } = useContext(LoggedInDetailsContext);
    return status;
};
